import styled from '@emotion/styled'


export const BlackButton = styled.button`
  background-color: black;
  color:white;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 0.25rem;
  width: 238px;

  &:hover {
    background-color: #333;
  }

`
